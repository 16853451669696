<template>
  <b-modal
    centered
    header-class="modal-header"
    modal-class="modal"
    title-class="modal-title"
    :title="$t('profile/edit_password')"
    hide-footer
    id="edit-modal-password"
    :ok-title-html="$t('g.ok')"
    :cancel-title="$t('cancel-button')"
  >
    <validation-observer ref="editPassword">
      <b-form @submit.prevent="validate">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              lab
              el-for="old_password"
              :label="$t('profile/old_password')"
            >
              <validation-provider
                #default="{ errors }"
                name="old password"
                vid="old_password"
                rules="required"
              >
                <b-form-input
                  id="old_password"
                  type="password"
                  class="profile-password"
                  v-model="form_data.old_password"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-for="new_password"
              :label="$t('profile/new_password')"
            >
              <validation-provider
                #default="{ errors }"
                name="new password"
                vid="new_password"
                rules="required|min:8"
              >
                <b-form-input
                  id="new_password"
                  type="password"
                  class="profile-new_password"
                  v-model="form_data.new_password"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-for="confirm_password"
              :label="$t('profile/confirm_password')"
            >
              <validation-provider
                #default="{ errors }"
                name="confirm password"
                vid="confirm_password"
                rules="required|confirmed:new_password"
              >
                <b-form-input
                  id="confirm_password"
                  type="password"
                  class="profile-password"
                  v-model="form_data.new_password_confirmation"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end" class="my-1">
          <b-button
            variant="outline"
            @click="$bvModal.hide('edit-modal-password')"
            >{{ $t("cancel") }}</b-button
          >
          <b-button variant="primary" class="mx-1" type="submit">{{
            $t("edit")
          }}</b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BForm,
} from "bootstrap-vue";

export default {
  name: "EditPassword",
  components: {
    BFormGroup,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      form_data: {
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      isLoading: false,
    };
  },
  methods: {
    validate() {
      this.$refs.editPassword
        .validate()
        .then((success) => success && this.editPassword());
    },
    async editPassword() {
      try {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("old_password", this.form_data.old_password);
        formData.append("new_password", this.form_data.new_password);
        formData.append(
          "new_password_confirmation",
          this.form_data.new_password_confirmation
        );

        const response = await this.$http.post(
          `/auth/edit-profile?_method=PUT`,
          formData
        );

        this.$helpers.makeToast(
          "success",
          response.data.message,
          response.data.message
        );
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
